import React from 'react'
import { injectIntl } from 'helpers'
import { Layout, Section, Container, ContactForm ,GMap } from 'components'

const About = props => (
  <Layout>
    <Section>
      <Container>
        <h1>Контакты</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt,
          voluptas nulla itaque voluptatem, accusamus mollitia ab tempore enim
          architecto, autem alias asperiores iure aspernatur sequi nihil sint ea
          omnis distinctio!
        </p>
        <h2>Адрес</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita,
          accusantium.
        </p>
        <h2>Реквизиты</h2>
        <ul>
          <li>Lorem ipsum dolor sit amet.</li>
          <li>Dignissimos veniam molestias repudiandae eum!</li>
          <li>Pariatur suscipit saepe illum consequatur!</li>
          <li>Inventore reiciendis nulla, molestias veritatis.</li>
          <li>Modi dicta, adipisci aspernatur maiores.</li>
        </ul>
      </Container>
    </Section>
    <Section>
      <Container>
        <ContactForm />
      </Container>
    </Section>
    <Section nopad>
      <GMap />
    </Section>
  </Layout>
)

export default injectIntl(About)
